import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { FFContextProvider } from "@harnessio/ff-react-client-sdk";

const root = ReactDOM.createRoot(document.getElementById("root"));

const reactTarget = { name: "ReactClientSDK", identifier: "reactclientsdk" };
const sdkApiKey = process.env.REACT_APP_HARNESS_KEY;

const isLocalDevelopment = process.env.REACT_APP_ENV === "dev";

const RootComponent = isLocalDevelopment ? (
  <React.StrictMode>
    <FFContextProvider apiKey={sdkApiKey} target={reactTarget}>
      <App />
    </FFContextProvider>
  </React.StrictMode>
) : (
  <FFContextProvider apiKey={sdkApiKey} target={reactTarget}>
    <App />
  </FFContextProvider>
);
root.render(RootComponent);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
