import TechRelevance from "./TechRelevance";
import TechRelevanceV3 from "./TechRelevanceV3";
import TechRelevanceV4 from "./TechRelevanceV4";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import AuthProvider from "./auth/AuthContext";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ContactUs from "./pages/ContactUs";

function App() {
  const NewTheme = useFeatureFlag("TechRelevance_New_Theme");
  return (
    <div className="App">
      {/* <AuthProvider>{NewTheme ? <TechRelevanceV4 /> : <TechRelevanceV3 />}</AuthProvider> */}
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={NewTheme ? <TechRelevanceV4 /> : <TechRelevanceV3 />} /> 
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
