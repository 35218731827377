import React, { useEffect } from "react";
import { Typography } from "@mui/material";

const GoogleTrendsEmbed = ({ keyword = "nodejs", geo = "", time = "today 5-y" }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://ssl.gstatic.com/trends_nrtr/3807_RC01/embed_loader.js";
    script.async = true;
    document.body.appendChild(script);

    const renderChart = () => {
      if (window.trends && window.trends.embed) {
        // Clear any existing chart
        document.getElementById("google-trends-widget").innerHTML = "";

        window.trends.embed.renderExploreWidgetTo(
          document.getElementById("google-trends-widget"),
          "TIMESERIES",
          {
            comparisonItem: [{ keyword, geo, time }],
            category: 31,
            property: "",
          },
          {
            exploreQuery: `cat=31&date=${time}&q=${keyword}&hl=en-GB`,
            guestPath: "https://trends.google.com:443/trends/embed/",
          }
        );
      }
    };

    script.onload = renderChart;

    // Cleanup the script and chart when the component is unmounted or keyword changes
    return () => {
      document.body.removeChild(script);
      if (document.getElementById("google-trends-widget")) {
        document.getElementById("google-trends-widget").innerHTML = "";
      }
    };
  }, [keyword, geo, time]);

  return (
    <div>
      <Typography
        sx={{
          fontFamily: "poppins",
          fontSize: { xs: "24px", md: "32px" },
          fontWeight: 700,
          color: "#262655",
          textAlign: { xs: "center", md: "center", lg: "left" },
          py: 2,
          pl: { xs: 0, md: 0, lg: 2.5 },
        }}
      >
        Google Trends : {keyword}
      </Typography>
      <div id="google-trends-widget" style={{ width: "100%", height: "100%" }} />
    </div>
  );
};

export default GoogleTrendsEmbed;
