import { Amplify } from "aws-amplify";
import { getRedirectSignInURL, getRedirectSignOutURL } from "./GlobalFunctions";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AWS_DOMAIN,
      redirectSignIn: getRedirectSignInURL(),
      redirectSignOut: getRedirectSignOutURL(),
      responseType: "code",
    },
  },
});
